<template>
  <woot-modal :show.sync="show" :on-close="onCancel">
    <div class="column content-box">
      <woot-modal-header :header-title="$t('CREATE_CASE_IN_CRM.TITLE')"
        :header-content="$t('CREATE_CASE_IN_CRM.DESC')" />
      <form @submit.prevent="onSubmit">
        <div class="modal-footer">
          <div class="medium-12 row">
            <woot-submit-button :button-text="$t('CREATE_CASE_IN_CRM.SUBMIT')" :disabled="false" />
            <button class="button clear" @click.prevent="onCancel">
              {{ $t('CREATE_CASE_IN_CRM.CANCEL') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </woot-modal>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import axios from 'axios';
const crmApiBaseUrl = 'https://spinocialapi.uzmancrm.com';

export default {
  mixins: [alertMixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    currentChat: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isSubmitting: false,
    };
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    async onSubmit() {
      this.isSubmitting = true;
      try {
        const applicationKey = process.env.APPLICATION_KEY;
        const apiUrl = `${crmApiBaseUrl}/api/Case/CaseRedirect/${process.env.MIDDLEWARE_WEBHOOK_KEY}`;

        const requestData = {
          AccountKey: `${applicationKey}-${this.currentChat.account_id?.toString()}`,
          AccountId: this.currentChat.account_id?.toString(),
          InboxId: this.currentChat.inbox_id?.toString(),
          ConversationId: this.currentChat.id?.toString(),
        };
        
        const response = await axios.post(apiUrl, requestData);

        if (response?.status === 200) {
          this.showAlert(this.$t('CONVERSATION.CRM.CREATE_CASE_SUCCESS'));
        } else {
          this.showAlert(this.$t('CONVERSATION.CRM.CREATE_CASE_ERROR'));
        }
        this.onCancel();
      } catch (error) {
        this.showAlert(this.$t('CONVERSATION.CRM.CREATE_CASE_ERROR'));
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>
