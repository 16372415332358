var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view-box columns bg-white" },
    [
      _c(
        "settings-header",
        {
          attrs: {
            "button-route": "new",
            "header-title": _vm.contact.name,
            "show-back-button": "",
            "back-button-label": _vm.$t("CONTACT_PROFILE.BACK_BUTTON"),
            "back-url": _vm.backUrl,
            "show-new-button": false
          }
        },
        [
          _vm.contact.thumbnail
            ? _c("thumbnail", {
                staticClass: "margin-right-1",
                attrs: {
                  src: _vm.contact.thumbnail,
                  username: _vm.contact.name,
                  size: "32px"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.uiFlags.isFetchingItem
        ? _c(
            "div",
            { staticClass: "text-center p-normal fs-default h-full" },
            [
              _c("spinner", { attrs: { size: "" } }),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.$t("CONTACT_PROFILE.LOADING")))])
            ],
            1
          )
        : _vm.contact.id
        ? _c(
            "div",
            {
              staticClass: "overflow-hidden column contact--dashboard-content"
            },
            [
              _c(
                "div",
                { staticClass: "row h-full" },
                [
                  _c("contact-info-panel", {
                    attrs: {
                      "show-close-button": false,
                      "show-avatar": false,
                      contact: _vm.contact
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "small-12 medium-9 h-full" },
                    [
                      _c(
                        "woot-tabs",
                        {
                          attrs: { index: _vm.selectedTabIndex },
                          on: { change: _vm.onClickTabChange }
                        },
                        _vm._l(_vm.tabs, function(tab) {
                          return _c("woot-tabs-item", {
                            key: tab.key,
                            attrs: { name: tab.name, "show-badge": false }
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "tab-content overflow-auto" },
                        [
                          _vm.selectedTabIndex === 0
                            ? _c("contact-notes", {
                                attrs: { "contact-id": Number(_vm.contactId) }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }