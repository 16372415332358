var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row content-box full-height" },
    [
      _c(
        "woot-button",
        {
          attrs: {
            "color-scheme": "success",
            "class-names": "button--fixed-top",
            icon: "add-circle"
          },
          on: { click: _vm.openAddPopup }
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("INTEGRATION_SETTINGS.WEBHOOK.HEADER_BTN_TXT")) +
              "\n  "
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "small-8 columns with-right-space " },
          [
            !_vm.uiFlags.fetchingList && !_vm.records.length
              ? _c("p", { staticClass: "no-items-error-message" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("INTEGRATION_SETTINGS.WEBHOOK.LIST.404")) +
                      "\n      "
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.uiFlags.fetchingList
              ? _c("woot-loading-state", {
                  attrs: {
                    message: _vm.$t("INTEGRATION_SETTINGS.WEBHOOK.LOADING")
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.uiFlags.fetchingList && _vm.records.length
              ? _c("table", { staticClass: "woot-table" }, [
                  _c(
                    "thead",
                    _vm._l(
                      _vm.$t("INTEGRATION_SETTINGS.WEBHOOK.LIST.TABLE_HEADER"),
                      function(thHeader) {
                        return _c("th", { key: thHeader }, [
                          _vm._v(
                            "\n            " + _vm._s(thHeader) + "\n          "
                          )
                        ])
                      }
                    ),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.records, function(webHookItem, index) {
                      return _c("webhook-row", {
                        key: webHookItem.id,
                        attrs: { index: index, webhook: webHookItem },
                        on: {
                          edit: _vm.openEditPopup,
                          delete: _vm.openDeletePopup
                        }
                      })
                    }),
                    1
                  )
                ])
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "small-4 columns" }, [
          _c("span", {
            directives: [
              {
                name: "dompurify-html",
                rawName: "v-dompurify-html",
                value: _vm.useInstallationName(
                  _vm.$t("INTEGRATION_SETTINGS.WEBHOOK.SIDEBAR_TXT"),
                  _vm.globalConfig.installationName
                ),
                expression:
                  "\n          useInstallationName(\n            $t('INTEGRATION_SETTINGS.WEBHOOK.SIDEBAR_TXT'),\n            globalConfig.installationName\n          )\n        "
              }
            ]
          })
        ])
      ]),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: { show: _vm.showAddPopup, "on-close": _vm.hideAddPopup },
          on: {
            "update:show": function($event) {
              _vm.showAddPopup = $event
            }
          }
        },
        [
          _vm.showAddPopup
            ? _c("new-webhook", { attrs: { "on-close": _vm.hideAddPopup } })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: { show: _vm.showEditPopup, "on-close": _vm.hideEditPopup },
          on: {
            "update:show": function($event) {
              _vm.showEditPopup = $event
            }
          }
        },
        [
          _vm.showEditPopup
            ? _c("edit-webhook", {
                attrs: {
                  id: _vm.selectedWebHook.id,
                  value: _vm.selectedWebHook,
                  "on-close": _vm.hideEditPopup
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("woot-delete-modal", {
        attrs: {
          show: _vm.showDeleteConfirmationPopup,
          "on-close": _vm.closeDeletePopup,
          "on-confirm": _vm.confirmDeletion,
          title: _vm.$t("INTEGRATION_SETTINGS.WEBHOOK.DELETE.CONFIRM.TITLE"),
          message: _vm.$t(
            "INTEGRATION_SETTINGS.WEBHOOK.DELETE.CONFIRM.MESSAGE",
            {
              webhookURL: _vm.selectedWebHook.url
            }
          ),
          "confirm-text": _vm.$t(
            "INTEGRATION_SETTINGS.WEBHOOK.DELETE.CONFIRM.YES"
          ),
          "reject-text": _vm.$t(
            "INTEGRATION_SETTINGS.WEBHOOK.DELETE.CONFIRM.NO"
          )
        },
        on: {
          "update:show": function($event) {
            _vm.showDeleteConfirmationPopup = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }