var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row content-box full-height" },
    [
      _c(
        "woot-button",
        {
          attrs: {
            "color-scheme": "success",
            "class-names": "button--fixed-top",
            icon: "add-circle"
          },
          on: { click: _vm.openCreatePopup }
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(
                _vm.$t("INTEGRATION_SETTINGS.DASHBOARD_APPS.HEADER_BTN_TXT")
              ) +
              "\n  "
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "small-8 columns with-right-space " },
          [
            !_vm.uiFlags.isFetching && !_vm.records.length
              ? _c("p", { staticClass: "no-items-error-message" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("INTEGRATION_SETTINGS.DASHBOARD_APPS.LIST.404")
                      ) +
                      "\n      "
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.uiFlags.isFetching
              ? _c("woot-loading-state", {
                  attrs: {
                    message: _vm.$t(
                      "INTEGRATION_SETTINGS.DASHBOARD_APPS.LIST.LOADING"
                    )
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.uiFlags.isFetching && _vm.records.length
              ? _c("table", { staticClass: "woot-table" }, [
                  _c(
                    "thead",
                    _vm._l(
                      _vm.$t(
                        "INTEGRATION_SETTINGS.DASHBOARD_APPS.LIST.TABLE_HEADER"
                      ),
                      function(thHeader) {
                        return _c("th", { key: thHeader }, [
                          _vm._v(
                            "\n            " + _vm._s(thHeader) + "\n          "
                          )
                        ])
                      }
                    ),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.records, function(dashboardAppItem, index) {
                      return _c("dashboard-apps-row", {
                        key: dashboardAppItem.id,
                        attrs: { index: index, app: dashboardAppItem },
                        on: { edit: _vm.editApp, delete: _vm.openDeletePopup }
                      })
                    }),
                    1
                  )
                ])
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "small-4 columns" }, [
          _c("span", {
            directives: [
              {
                name: "dompurify-html",
                rawName: "v-dompurify-html",
                value: _vm.useInstallationName(
                  _vm.$t("INTEGRATION_SETTINGS.DASHBOARD_APPS.SIDEBAR_TXT"),
                  _vm.globalConfig.installationName
                ),
                expression:
                  "\n          useInstallationName(\n            $t('INTEGRATION_SETTINGS.DASHBOARD_APPS.SIDEBAR_TXT'),\n            globalConfig.installationName\n          )\n        "
              }
            ]
          })
        ])
      ]),
      _vm._v(" "),
      _vm.showDashboardAppPopup
        ? _c("dashboard-app-modal", {
            attrs: {
              show: _vm.showDashboardAppPopup,
              mode: _vm.mode,
              "selected-app-data": _vm.selectedApp
            },
            on: { close: _vm.toggleDashboardAppPopup }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("woot-delete-modal", {
        attrs: {
          show: _vm.showDeleteConfirmationPopup,
          "on-close": _vm.closeDeletePopup,
          "on-confirm": _vm.confirmDeletion,
          title: _vm.$t("INTEGRATION_SETTINGS.DASHBOARD_APPS.DELETE.TITLE"),
          message: _vm.$t(
            "INTEGRATION_SETTINGS.DASHBOARD_APPS.DELETE.MESSAGE",
            {
              appName: _vm.selectedApp.title
            }
          ),
          "confirm-text": _vm.$t(
            "INTEGRATION_SETTINGS.DASHBOARD_APPS.DELETE.CONFIRM_YES"
          ),
          "reject-text": _vm.$t(
            "INTEGRATION_SETTINGS.DASHBOARD_APPS.DELETE.CONFIRM_NO"
          )
        },
        on: {
          "update:show": function($event) {
            _vm.showDeleteConfirmationPopup = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }