var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "message-text__wrap",
      class: {
        "show--quoted": _vm.isQuotedContentPresent,
        "hide--quoted": !_vm.isQuotedContentPresent
      }
    },
    [
      !_vm.isEmail
        ? _c("div", {
            directives: [
              {
                name: "dompurify-html",
                rawName: "v-dompurify-html",
                value: _vm.message,
                expression: "message"
              }
            ],
            staticClass: "text-content"
          })
        : _c("letter", {
            staticClass: "text-content",
            attrs: { html: _vm.message }
          }),
      _vm._v(" "),
      _vm.showQuoteToggle
        ? _c(
            "button",
            {
              staticClass: "quoted-text--button",
              on: { click: _vm.toggleQuotedContent }
            },
            [
              _vm.showQuotedContent
                ? _c(
                    "span",
                    [
                      _c("fluent-icon", {
                        staticClass: "fluent-icon",
                        attrs: { icon: "chevron-up", size: "16" }
                      }),
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.$t("CHAT_LIST.HIDE_QUOTED_TEXT")) +
                          "\n    "
                      )
                    ],
                    1
                  )
                : _c(
                    "span",
                    [
                      _c("fluent-icon", {
                        staticClass: "fluent-icon",
                        attrs: { icon: "chevron-down", size: "16" }
                      }),
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.$t("CHAT_LIST.SHOW_QUOTED_TEXT")) +
                          "\n    "
                      )
                    ],
                    1
                  )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }