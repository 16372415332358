import { render, staticRenderFns } from "./FinishSetup.vue?vue&type=template&id=73a58d64&scoped=true&"
import script from "./FinishSetup.vue?vue&type=script&lang=js&"
export * from "./FinishSetup.vue?vue&type=script&lang=js&"
import style0 from "./FinishSetup.vue?vue&type=style&index=0&id=73a58d64&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73a58d64",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/uzmancrm/Spinocial/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('73a58d64')) {
      api.createRecord('73a58d64', component.options)
    } else {
      api.reload('73a58d64', component.options)
    }
    module.hot.accept("./FinishSetup.vue?vue&type=template&id=73a58d64&scoped=true&", function () {
      api.rerender('73a58d64', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/dashboard/routes/dashboard/settings/teams/FinishSetup.vue"
export default component.exports