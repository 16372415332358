var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "result-section" },
    [
      _c("div", { staticClass: "header" }, [
        _c("h3", { staticClass: "text-block-title" }, [
          _vm._v(_vm._s(_vm.title))
        ])
      ]),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _vm.empty
        ? _c(
            "div",
            { staticClass: "empty" },
            [
              _c("fluent-icon", {
                staticClass: "icon",
                attrs: { icon: "info", size: "16px" }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "empty-state__text" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$t("SEARCH.EMPTY_STATE", {
                        item: _vm.titleCase,
                        query: _vm.query
                      })
                    ) +
                    "\n    "
                )
              ])
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }