import { render, staticRenderFns } from "./TableFooter.vue?vue&type=template&id=ed5bce7e&scoped=true&"
import script from "./TableFooter.vue?vue&type=script&lang=js&"
export * from "./TableFooter.vue?vue&type=script&lang=js&"
import style0 from "./TableFooter.vue?vue&type=style&index=0&id=ed5bce7e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed5bce7e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/uzmancrm/Spinocial/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ed5bce7e')) {
      api.createRecord('ed5bce7e', component.options)
    } else {
      api.reload('ed5bce7e', component.options)
    }
    module.hot.accept("./TableFooter.vue?vue&type=template&id=ed5bce7e&scoped=true&", function () {
      api.rerender('ed5bce7e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/dashboard/components/widgets/TableFooter.vue"
export default component.exports