var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "blockquote",
    { ref: "messageContainer", staticClass: "message" },
    [
      _c("p", { staticClass: "header" }, [
        _c("strong", { staticClass: "author" }, [
          _vm._v("\n      " + _vm._s(_vm.author) + "\n    ")
        ]),
        _vm._v("\n    " + _vm._s(_vm.$t("SEARCH.WROTE")) + "\n  ")
      ]),
      _vm._v(" "),
      _c(
        "read-more",
        {
          attrs: { shrink: _vm.isOverflowing },
          on: {
            expand: function($event) {
              _vm.isOverflowing = false
            }
          }
        },
        [
          _c("div", {
            directives: [
              {
                name: "dompurify-html",
                rawName: "v-dompurify-html",
                value: _vm.prepareContent(_vm.content),
                expression: "prepareContent(content)"
              }
            ],
            staticClass: "message-content"
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }