var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "column" }, [
      _c("div", [
        _c("h5", [
          _vm._v(_vm._s(_vm.$t("INTEGRATION_SETTINGS.SLACK.HELP_TEXT.TITLE")))
        ]),
        _vm._v(" "),
        _c("p", [
          _c("span", {
            directives: [
              {
                name: "dompurify-html",
                rawName: "v-dompurify-html",
                value: _vm.$t("INTEGRATION_SETTINGS.SLACK.HELP_TEXT.BODY"),
                expression: "$t('INTEGRATION_SETTINGS.SLACK.HELP_TEXT.BODY')"
              }
            ]
          })
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }