var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    { staticClass: "contact-item", attrs: { to: _vm.navigateTo } },
    [
      _c("woot-thumbnail", {
        attrs: { src: _vm.thumbnail, username: _vm.name, size: "24px" }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "contact-details" }, [
        _c("h5", { staticClass: "text-block-title name" }, [
          _vm._v(_vm._s(_vm.name))
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "details-meta" }, [
          _vm.email
            ? _c("span", { staticClass: "email" }, [_vm._v(_vm._s(_vm.email))])
            : _vm._e(),
          _vm._v(" "),
          _vm.phone
            ? _c("span", { staticClass: "separator" }, [_vm._v("•")])
            : _vm._e(),
          _vm._v(" "),
          _vm.phone
            ? _c("span", { staticClass: "phone" }, [
                _vm._v("\n        " + _vm._s(_vm.phone) + "\n      ")
              ])
            : _vm._e()
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }