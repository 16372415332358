var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    { staticClass: "conversation-item", attrs: { to: _vm.navigateTo } },
    [
      _c(
        "div",
        { staticClass: "icon-wrap" },
        [_c("fluent-icon", { attrs: { icon: "chat-multiple", size: 14 } })],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "conversation-details" },
        [
          _c("div", { staticClass: "meta-wrap" }, [
            _c(
              "div",
              { staticClass: "flex-container " },
              [
                _c("woot-label", {
                  staticClass: "conversation-id",
                  attrs: { title: "#" + _vm.id, "show-close": false, small: "" }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "inbox-name-wrap" },
                  [
                    _c("inbox-name", {
                      staticClass: "margin-right-1",
                      attrs: { inbox: _vm.inbox }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("div", [
              _c("span", { staticClass: "created-at" }, [
                _vm._v(_vm._s(_vm.createdAtTime))
              ])
            ])
          ]),
          _vm._v(" "),
          _vm.name
            ? _c("h5", { staticClass: "text-block-title name" }, [
                _c("span", { staticClass: "pre-text" }, [_vm._v("from:")]),
                _vm._v("\n      " + _vm._s(_vm.name) + "\n    ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._t("default")
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }