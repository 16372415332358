import { render, staticRenderFns } from "./SearchResultContactItem.vue?vue&type=template&id=6ad42033&scoped=true&"
import script from "./SearchResultContactItem.vue?vue&type=script&lang=js&"
export * from "./SearchResultContactItem.vue?vue&type=script&lang=js&"
import style0 from "./SearchResultContactItem.vue?vue&type=style&index=0&id=6ad42033&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ad42033",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/uzmancrm/Spinocial/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6ad42033')) {
      api.createRecord('6ad42033', component.options)
    } else {
      api.reload('6ad42033', component.options)
    }
    module.hot.accept("./SearchResultContactItem.vue?vue&type=template&id=6ad42033&scoped=true&", function () {
      api.rerender('6ad42033', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/dashboard/modules/search/components/SearchResultContactItem.vue"
export default component.exports