import { render, staticRenderFns } from "./ReplyBox.vue?vue&type=template&id=6f625285&scoped=true&"
import script from "./ReplyBox.vue?vue&type=script&lang=js&"
export * from "./ReplyBox.vue?vue&type=script&lang=js&"
import style0 from "./ReplyBox.vue?vue&type=style&index=0&id=6f625285&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f625285",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/uzmancrm/Spinocial/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6f625285')) {
      api.createRecord('6f625285', component.options)
    } else {
      api.reload('6f625285', component.options)
    }
    module.hot.accept("./ReplyBox.vue?vue&type=template&id=6f625285&scoped=true&", function () {
      api.rerender('6f625285', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/dashboard/components/widgets/conversation/ReplyBox.vue"
export default component.exports