var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row app-wrapper" },
    [
      _c("sidebar", {
        attrs: { route: _vm.currentRoute },
        on: {
          "toggle-account-modal": _vm.toggleAccountModal,
          "open-notification-panel": _vm.openNotificationPanel,
          "open-key-shortcut-modal": _vm.toggleKeyShortcutModal,
          "close-key-shortcut-modal": _vm.closeKeyShortcutModal
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "secondary-sidebar" },
        [
          _vm.showHelpCenterSidebar
            ? _c("help-center-sidebar", {
                attrs: {
                  "header-title": _vm.headerTitle,
                  "portal-slug": _vm.selectedPortalSlug,
                  "locale-slug": _vm.selectedLocaleInPortal,
                  "sub-title": _vm.localeName(_vm.selectedLocaleInPortal),
                  "accessible-menu-items": _vm.accessibleMenuItems,
                  "additional-secondary-menu-items":
                    _vm.additionalSecondaryMenuItems
                },
                on: {
                  "open-popover": _vm.openPortalPopover,
                  "open-modal": _vm.onClickOpenAddCategoryModal
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "app-content columns" },
        [
          _c("router-view"),
          _vm._v(" "),
          _c("command-bar"),
          _vm._v(" "),
          _c("account-selector", {
            attrs: { "show-account-modal": _vm.showAccountModal },
            on: { "close-account-modal": _vm.toggleAccountModal }
          }),
          _vm._v(" "),
          _vm.showShortcutModal
            ? _c("woot-key-shortcut-modal", {
                on: {
                  close: _vm.closeKeyShortcutModal,
                  clickaway: _vm.closeKeyShortcutModal
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showNotificationPanel
            ? _c("notification-panel", {
                on: { close: _vm.closeNotificationPanel }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showPortalPopover
            ? _c("portal-popover", {
                attrs: {
                  portals: _vm.portals,
                  "active-portal-slug": _vm.selectedPortalSlug,
                  "active-locale": _vm.selectedLocaleInPortal
                },
                on: {
                  "fetch-portal": _vm.fetchPortalAndItsCategories,
                  "close-popover": _vm.closePortalPopover
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showAddCategoryModal
            ? _c("add-category", {
                attrs: {
                  show: _vm.showAddCategoryModal,
                  "portal-name": _vm.selectedPortalName,
                  locale: _vm.selectedLocaleInPortal,
                  "portal-slug": _vm.selectedPortalSlug
                },
                on: {
                  "update:show": function($event) {
                    _vm.showAddCategoryModal = $event
                  },
                  cancel: _vm.onClickCloseAddCategoryModal
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }