var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "input-container",
      class: { "is-focused": _vm.isInputFocused }
    },
    [
      _c(
        "div",
        { staticClass: "icon-container" },
        [
          _c("fluent-icon", {
            staticClass: "icon",
            attrs: { icon: "search", "aria-hidden": "true" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("input", {
        ref: "searchInput",
        attrs: {
          type: "search",
          placeholder: _vm.$t("SEARCH.INPUT_PLACEHOLDER")
        },
        domProps: { value: _vm.searchQuery },
        on: { focus: _vm.onFocus, blur: _vm.onBlur, input: _vm.debounceSearch }
      }),
      _vm._v(" "),
      _c("woot-label", {
        attrs: {
          title: _vm.$t("SEARCH.PLACEHOLDER_KEYBINDING"),
          "show-close": false,
          small: ""
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }