var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "conversations-list-wrap",
      class: {
        hide: !_vm.showConversationList,
        "list--full-width": _vm.isOnExpandedLayout
      }
    },
    [
      _vm._t("default"),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "chat-list__top",
          class: { filter__applied: _vm.hasAppliedFiltersOrActiveFolders }
        },
        [
          _c(
            "h1",
            {
              staticClass: "page-title text-truncate",
              attrs: { title: _vm.pageTitle }
            },
            [_vm._v("\n      " + _vm._s(_vm.pageTitle) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "filter--actions" },
            [
              !_vm.hasAppliedFiltersOrActiveFolders
                ? _c("chat-filter", {
                    on: { statusFilterChange: _vm.updateStatusType }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.hasAppliedFilters && !_vm.hasActiveFolders
                ? _c(
                    "div",
                    [
                      _c("woot-button", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip.top-end",
                            value: _vm.$t(
                              "FILTER.CUSTOM_VIEWS.ADD.SAVE_BUTTON"
                            ),
                            expression:
                              "$t('FILTER.CUSTOM_VIEWS.ADD.SAVE_BUTTON')",
                            modifiers: { "top-end": true }
                          }
                        ],
                        attrs: {
                          size: "tiny",
                          variant: "smooth",
                          "color-scheme": "secondary",
                          icon: "save"
                        },
                        on: { click: _vm.onClickOpenAddFoldersModal }
                      }),
                      _vm._v(" "),
                      _c("woot-button", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip.top-end",
                            value: _vm.$t("FILTER.CLEAR_BUTTON_LABEL"),
                            expression: "$t('FILTER.CLEAR_BUTTON_LABEL')",
                            modifiers: { "top-end": true }
                          }
                        ],
                        attrs: {
                          size: "tiny",
                          variant: "smooth",
                          "color-scheme": "alert",
                          icon: "dismiss-circle"
                        },
                        on: { click: _vm.resetAndFetchData }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.hasActiveFolders
                ? _c(
                    "div",
                    [
                      _c("woot-button", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip.top-end",
                            value: _vm.$t(
                              "FILTER.CUSTOM_VIEWS.DELETE.DELETE_BUTTON"
                            ),
                            expression:
                              "$t('FILTER.CUSTOM_VIEWS.DELETE.DELETE_BUTTON')",
                            modifiers: { "top-end": true }
                          }
                        ],
                        attrs: {
                          size: "tiny",
                          variant: "smooth",
                          "color-scheme": "alert",
                          icon: "delete"
                        },
                        on: { click: _vm.onClickOpenDeleteFoldersModal }
                      })
                    ],
                    1
                  )
                : _c("woot-button", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip.right",
                        value: _vm.$t("FILTER.TOOLTIP_LABEL"),
                        expression: "$t('FILTER.TOOLTIP_LABEL')",
                        modifiers: { right: true }
                      }
                    ],
                    attrs: {
                      variant: "smooth",
                      "color-scheme": "secondary",
                      icon: "filter",
                      size: "tiny"
                    },
                    on: { click: _vm.onToggleAdvanceFiltersModal }
                  })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _vm.showAddFoldersModal
        ? _c("add-custom-views", {
            attrs: {
              "custom-views-query": _vm.foldersQuery,
              "open-last-saved-item": _vm.openLastSavedItemInFolder
            },
            on: { close: _vm.onCloseAddFoldersModal }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showDeleteFoldersModal
        ? _c("delete-custom-views", {
            attrs: {
              "show-delete-popup": _vm.showDeleteFoldersModal,
              "active-custom-view": _vm.activeFolder,
              "custom-views-id": _vm.foldersId,
              "open-last-item-after-delete": _vm.openLastItemAfterDeleteInFolder
            },
            on: {
              "update:showDeletePopup": function($event) {
                _vm.showDeleteFoldersModal = $event
              },
              "update:show-delete-popup": function($event) {
                _vm.showDeleteFoldersModal = $event
              },
              close: _vm.onCloseDeleteFoldersModal
            }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasAppliedFiltersOrActiveFolders
        ? _c("chat-type-tabs", {
            staticClass: "tab--chat-type",
            attrs: {
              items: _vm.assigneeTabItems,
              "active-tab": _vm.activeAssigneeTab
            },
            on: { chatTabChange: _vm.updateAssigneeTab }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.chatListLoading && !_vm.conversationList.length
        ? _c("p", { staticClass: "content-box" }, [
            _vm._v("\n    " + _vm._s(_vm.$t("CHAT_LIST.LIST.404")) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedConversations.length
        ? _c("conversation-bulk-actions", {
            attrs: {
              conversations: _vm.selectedConversations,
              "all-conversations-selected": _vm.allConversationsSelected,
              "selected-inboxes": _vm.uniqueInboxes,
              "show-open-action": _vm.allSelectedConversationsStatus("open"),
              "show-resolved-action": _vm.allSelectedConversationsStatus(
                "resolved"
              ),
              "show-snoozed-action": _vm.allSelectedConversationsStatus(
                "snoozed"
              )
            },
            on: {
              "select-all-conversations": _vm.selectAllConversations,
              "assign-agent": _vm.onAssignAgent,
              "update-conversations": _vm.onUpdateConversations,
              "assign-labels": _vm.onAssignLabels,
              "assign-team": _vm.onAssignTeamsForBulk
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "activeConversation",
          staticClass: "conversations-list",
          class: { "is-context-menu-open": _vm.isContextMenuOpen }
        },
        [
          _vm._l(_vm.conversationList, function(chat) {
            return _c("conversation-card", {
              key: chat.id,
              attrs: {
                "active-label": _vm.label,
                "team-id": _vm.teamId,
                "folders-id": _vm.foldersId,
                chat: chat,
                "conversation-type": _vm.conversationType,
                "show-assignee": _vm.showAssigneeInConversationCard,
                selected: _vm.isConversationSelected(chat.id)
              },
              on: {
                "select-conversation": _vm.selectConversation,
                "de-select-conversation": _vm.deSelectConversation,
                "assign-agent": _vm.onAssignAgent,
                "assign-team": _vm.onAssignTeam,
                "assign-label": _vm.onAssignLabels,
                "update-conversation-status": _vm.toggleConversationStatus,
                "context-menu-toggle": _vm.onContextMenuToggle,
                "mark-as-unread": _vm.markAsUnread
              }
            })
          }),
          _vm._v(" "),
          _vm.chatListLoading
            ? _c("div", { staticClass: "text-center" }, [
                _c("span", { staticClass: "spinner" })
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.hasCurrentPageEndReached && !_vm.chatListLoading
            ? _c(
                "woot-button",
                {
                  attrs: { variant: "clear", size: "expanded" },
                  on: { click: _vm.loadMoreConversations }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("CHAT_LIST.LOAD_MORE_CONVERSATIONS")) +
                      "\n    "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showEndOfListMessage
            ? _c(
                "p",
                { staticClass: "text-center text-muted end-of-list-text" },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("CHAT_LIST.EOF")) + "\n    "
                  )
                ]
              )
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: {
            show: _vm.showAdvancedFilters,
            "on-close": _vm.closeAdvanceFiltersModal,
            size: "medium"
          },
          on: {
            "update:show": function($event) {
              _vm.showAdvancedFilters = $event
            }
          }
        },
        [
          _vm.showAdvancedFilters
            ? _c("conversation-advanced-filter", {
                attrs: {
                  "initial-filter-types": _vm.advancedFilterTypes,
                  "initial-applied-filters": _vm.appliedFilter,
                  "on-close": _vm.closeAdvanceFiltersModal
                },
                on: { applyFilter: _vm.onApplyFilter }
              })
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }