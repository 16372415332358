var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row--user-block" },
    [
      _c("thumbnail", {
        attrs: {
          src: _vm.user.thumbnail,
          size: _vm.size,
          username: _vm.user.name,
          status: _vm.user.availability_status
        }
      }),
      _vm._v(" "),
      _c(
        "h6",
        {
          staticClass:
            "text-block-title user-name text-truncate text-capitalize"
        },
        [_vm._v("\n    " + _vm._s(_vm.user.name) + "\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }