var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "heatmap-container" },
    [
      _vm.isLoading
        ? [
            _c(
              "div",
              { staticClass: "heatmap-labels" },
              _vm._l(7, function(ii) {
                return _c("div", {
                  key: ii,
                  staticClass: "loading-cell heatmap-axis-label"
                })
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "heatmap-grid" },
              _vm._l(7, function(ii) {
                return _c(
                  "div",
                  { key: ii, staticClass: "heatmap-grid-row" },
                  _vm._l(24, function(jj) {
                    return _c(
                      "div",
                      { key: jj, staticClass: "heatmap-tile loading-cell" },
                      [
                        _c("div", {
                          staticClass: "heatmap-tile__label loading-cell"
                        })
                      ]
                    )
                  }),
                  0
                )
              }),
              0
            ),
            _vm._v(" "),
            _c("div", { staticClass: "heatmap-timeline" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "heatmap-markers" },
              _vm._l(24, function(ii) {
                return _c("div", { key: ii }, [
                  _vm._v(_vm._s(ii - 1) + " – " + _vm._s(ii))
                ])
              }),
              0
            )
          ]
        : [
            _c(
              "div",
              { staticClass: "heatmap-labels" },
              _vm._l(_vm.processedData.keys(), function(dateKey) {
                return _c(
                  "div",
                  { key: dateKey, staticClass: "heatmap-axis-label" },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.getDayOfTheWeek(new Date(dateKey))) +
                        "\n        "
                    ),
                    _c("time", [_vm._v(_vm._s(_vm.formatDate(dateKey)))])
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "heatmap-grid" },
              _vm._l(_vm.processedData.keys(), function(dateKey) {
                return _c(
                  "div",
                  { key: dateKey, staticClass: "heatmap-grid-row" },
                  _vm._l(_vm.processedData.get(dateKey), function(data) {
                    return _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip.top",
                            value: _vm.getCountTooltip(data.value),
                            expression: "getCountTooltip(data.value)",
                            modifiers: { top: true }
                          }
                        ],
                        key: data.timestamp,
                        staticClass: "heatmap-tile",
                        class: _vm.getHeatmapLevelClass(data.value)
                      },
                      [_c("div", { staticClass: "heatmap-tile__label" })]
                    )
                  }),
                  0
                )
              }),
              0
            ),
            _vm._v(" "),
            _c("div", { staticClass: "heatmap-timeline" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "heatmap-markers" },
              _vm._l(24, function(ii) {
                return _c("div", { key: ii }, [
                  _vm._v(_vm._s(ii - 1) + " – " + _vm._s(ii))
                ])
              }),
              0
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }