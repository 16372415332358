var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("woot-button", {
    directives: [
      {
        name: "tooltip",
        rawName: "v-tooltip.left",
        value: _vm.$t("CONVERSATION.SWITCH_VIEW_LAYOUT"),
        expression: "$t('CONVERSATION.SWITCH_VIEW_LAYOUT')",
        modifiers: { left: true }
      }
    ],
    staticClass: "layout-switch__container",
    class: { expanded: _vm.isOnExpandedLayout },
    attrs: {
      icon: "arrow-right-import",
      size: "tiny",
      variant: "smooth",
      "color-scheme": "secondary"
    },
    on: { click: _vm.toggle }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }