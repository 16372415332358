var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "search-result-section",
    {
      attrs: {
        title: _vm.$t("SEARCH.SECTION.CONVERSATIONS"),
        empty: !_vm.conversations.length,
        query: _vm.query
      }
    },
    [
      _c(
        "ul",
        { staticClass: "search-list" },
        _vm._l(_vm.conversations, function(conversation) {
          return _c(
            "li",
            { key: conversation.id },
            [
              _c("search-result-conversation-item", {
                attrs: {
                  id: conversation.id,
                  name: conversation.contact.name,
                  "account-id": _vm.accountId,
                  inbox: conversation.inbox,
                  "created-at": conversation.created_at
                }
              })
            ],
            1
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }