var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text--container" },
    [
      _c(
        "woot-button",
        {
          staticClass: " button--text",
          attrs: { size: "small" },
          on: { click: _vm.onCopy }
        },
        [
          _vm._v(
            "\n    " + _vm._s(_vm.$t("COMPONENTS.CODE.BUTTON_TEXT")) + "\n  "
          )
        ]
      ),
      _vm._v(" "),
      _c("woot-button", {
        staticClass: "button--visibility",
        attrs: {
          variant: "clear",
          size: "small",
          "color-scheme": "secondary",
          icon: _vm.masked ? "eye-show" : "eye-hide"
        },
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.toggleMasked($event)
          }
        }
      }),
      _vm._v(" "),
      _vm.value
        ? _c("highlightjs", {
            attrs: { code: _vm.masked ? "•".repeat(10) : _vm.value }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }