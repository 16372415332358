var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box" },
    [
      _c("woot-modal-header", {
        attrs: {
          "header-title": _vm.$t("CAMPAIGN.ADD.TITLE"),
          "header-content": _vm.$t("CAMPAIGN.ADD.DESC")
        }
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "row",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.addCampaign($event)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "medium-12 columns" },
            [
              _c("woot-input", {
                class: { error: _vm.$v.title.$error },
                attrs: {
                  label: _vm.$t("CAMPAIGN.ADD.FORM.TITLE.LABEL"),
                  type: "text",
                  error: _vm.$v.title.$error
                    ? _vm.$t("CAMPAIGN.ADD.FORM.TITLE.ERROR")
                    : "",
                  placeholder: _vm.$t("CAMPAIGN.ADD.FORM.TITLE.PLACEHOLDER")
                },
                on: { blur: _vm.$v.title.$touch },
                model: {
                  value: _vm.title,
                  callback: function($$v) {
                    _vm.title = $$v
                  },
                  expression: "title"
                }
              }),
              _vm._v(" "),
              _vm.isOngoingType
                ? _c(
                    "label",
                    { staticClass: "editor-wrap" },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("CAMPAIGN.ADD.FORM.MESSAGE.LABEL")) +
                          "\n        "
                      ),
                      _c("woot-message-editor", {
                        staticClass: "message-editor",
                        class: { editor_warning: _vm.$v.message.$error },
                        attrs: {
                          placeholder: _vm.$t(
                            "CAMPAIGN.ADD.FORM.MESSAGE.PLACEHOLDER"
                          )
                        },
                        on: { blur: _vm.$v.message.$touch },
                        model: {
                          value: _vm.message,
                          callback: function($$v) {
                            _vm.message = $$v
                          },
                          expression: "message"
                        }
                      }),
                      _vm._v(" "),
                      _vm.$v.message.$error
                        ? _c(
                            "span",
                            { staticClass: "editor-warning__message" },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.$t("CAMPAIGN.ADD.FORM.MESSAGE.ERROR")
                                  ) +
                                  "\n        "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _c("label", { class: { error: _vm.$v.message.$error } }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("CAMPAIGN.ADD.FORM.MESSAGE.LABEL")) +
                        "\n        "
                    ),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.message,
                          expression: "message"
                        }
                      ],
                      attrs: {
                        rows: "5",
                        type: "text",
                        placeholder: _vm.$t(
                          "CAMPAIGN.ADD.FORM.MESSAGE.PLACEHOLDER"
                        )
                      },
                      domProps: { value: _vm.message },
                      on: {
                        blur: _vm.$v.message.$touch,
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.message = $event.target.value
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.$v.message.$error
                      ? _c("span", { staticClass: "message" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t("CAMPAIGN.ADD.FORM.MESSAGE.ERROR")
                              ) +
                              "\n        "
                          )
                        ])
                      : _vm._e()
                  ]),
              _vm._v(" "),
              _c("label", { class: { error: _vm.$v.selectedInbox.$error } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("CAMPAIGN.ADD.FORM.INBOX.LABEL")) +
                    "\n        "
                ),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedInbox,
                        expression: "selectedInbox"
                      }
                    ],
                    on: {
                      change: [
                        function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.selectedInbox = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        function($event) {
                          return _vm.onChangeInbox($event)
                        }
                      ]
                    }
                  },
                  _vm._l(_vm.inboxes, function(item) {
                    return _c(
                      "option",
                      { key: item.name, domProps: { value: item.id } },
                      [
                        _vm._v(
                          "\n            " + _vm._s(item.name) + "\n          "
                        )
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _vm.$v.selectedInbox.$error
                  ? _c("span", { staticClass: "message" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("CAMPAIGN.ADD.FORM.INBOX.ERROR")) +
                          "\n        "
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _vm.isOnOffType
                ? _c(
                    "label",
                    {
                      staticClass: "multiselect-wrap--small",
                      class: { error: _vm.$v.selectedAudience.$error }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("CAMPAIGN.ADD.FORM.AUDIENCE.LABEL")) +
                          "\n        "
                      ),
                      _c("multiselect", {
                        attrs: {
                          options: _vm.audienceList,
                          "track-by": "id",
                          label: "title",
                          multiple: true,
                          "close-on-select": false,
                          "clear-on-select": false,
                          "hide-selected": true,
                          placeholder: _vm.$t(
                            "CAMPAIGN.ADD.FORM.AUDIENCE.PLACEHOLDER"
                          ),
                          "selected-label": "",
                          "select-label": _vm.$t(
                            "FORMS.MULTISELECT.ENTER_TO_SELECT"
                          ),
                          "deselect-label": _vm.$t(
                            "FORMS.MULTISELECT.ENTER_TO_REMOVE"
                          )
                        },
                        on: {
                          blur: _vm.$v.selectedAudience.$touch,
                          select: _vm.$v.selectedAudience.$touch
                        },
                        model: {
                          value: _vm.selectedAudience,
                          callback: function($$v) {
                            _vm.selectedAudience = $$v
                          },
                          expression: "selectedAudience"
                        }
                      }),
                      _vm._v(" "),
                      _vm.$v.selectedAudience.$error
                        ? _c("span", { staticClass: "message" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$t("CAMPAIGN.ADD.FORM.AUDIENCE.ERROR")
                                ) +
                                "\n        "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isOngoingType
                ? _c(
                    "label",
                    { class: { error: _vm.$v.selectedSender.$error } },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("CAMPAIGN.ADD.FORM.SENT_BY.LABEL")) +
                          "\n        "
                      ),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedSender,
                              expression: "selectedSender"
                            }
                          ],
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.selectedSender = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        _vm._l(_vm.sendersAndBotList, function(sender) {
                          return _c(
                            "option",
                            {
                              key: sender.name,
                              domProps: { value: sender.id }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(sender.name) +
                                  "\n          "
                              )
                            ]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _vm.$v.selectedSender.$error
                        ? _c("span", { staticClass: "message" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$t("CAMPAIGN.ADD.FORM.SENT_BY.ERROR")
                                ) +
                                "\n        "
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isOnOffType
                ? _c(
                    "label",
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("CAMPAIGN.ADD.FORM.SCHEDULED_AT.LABEL")
                          ) +
                          "\n        "
                      ),
                      _c("woot-date-time-picker", {
                        attrs: {
                          value: _vm.scheduledAt,
                          "confirm-text": _vm.$t(
                            "CAMPAIGN.ADD.FORM.SCHEDULED_AT.CONFIRM"
                          ),
                          placeholder: _vm.$t(
                            "CAMPAIGN.ADD.FORM.SCHEDULED_AT.PLACEHOLDER"
                          )
                        },
                        on: { change: _vm.onChange }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isOngoingType
                ? _c("woot-input", {
                    class: { error: _vm.$v.endPoint.$error },
                    attrs: {
                      label: _vm.$t("CAMPAIGN.ADD.FORM.END_POINT.LABEL"),
                      type: "text",
                      error: _vm.$v.endPoint.$error
                        ? _vm.$t("CAMPAIGN.ADD.FORM.END_POINT.ERROR")
                        : "",
                      placeholder: _vm.$t(
                        "CAMPAIGN.ADD.FORM.END_POINT.PLACEHOLDER"
                      )
                    },
                    on: { blur: _vm.$v.endPoint.$touch },
                    model: {
                      value: _vm.endPoint,
                      callback: function($$v) {
                        _vm.endPoint = $$v
                      },
                      expression: "endPoint"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.isOngoingType
                ? _c("woot-input", {
                    class: { error: _vm.$v.timeOnPage.$error },
                    attrs: {
                      label: _vm.$t("CAMPAIGN.ADD.FORM.TIME_ON_PAGE.LABEL"),
                      type: "text",
                      error: _vm.$v.timeOnPage.$error
                        ? _vm.$t("CAMPAIGN.ADD.FORM.TIME_ON_PAGE.ERROR")
                        : "",
                      placeholder: _vm.$t(
                        "CAMPAIGN.ADD.FORM.TIME_ON_PAGE.PLACEHOLDER"
                      )
                    },
                    on: { blur: _vm.$v.timeOnPage.$touch },
                    model: {
                      value: _vm.timeOnPage,
                      callback: function($$v) {
                        _vm.timeOnPage = $$v
                      },
                      expression: "timeOnPage"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.isOngoingType
                ? _c("label", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.enabled,
                          expression: "enabled"
                        }
                      ],
                      attrs: {
                        type: "checkbox",
                        value: "enabled",
                        name: "enabled"
                      },
                      domProps: {
                        checked: Array.isArray(_vm.enabled)
                          ? _vm._i(_vm.enabled, "enabled") > -1
                          : _vm.enabled
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.enabled,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "enabled",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.enabled = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.enabled = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.enabled = $$c
                          }
                        }
                      }
                    }),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("CAMPAIGN.ADD.FORM.ENABLED")) +
                        "\n      "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isOngoingType
                ? _c("label", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.triggerOnlyDuringBusinessHours,
                          expression: "triggerOnlyDuringBusinessHours"
                        }
                      ],
                      attrs: {
                        type: "checkbox",
                        value: "triggerOnlyDuringBusinessHours",
                        name: "triggerOnlyDuringBusinessHours"
                      },
                      domProps: {
                        checked: Array.isArray(
                          _vm.triggerOnlyDuringBusinessHours
                        )
                          ? _vm._i(
                              _vm.triggerOnlyDuringBusinessHours,
                              "triggerOnlyDuringBusinessHours"
                            ) > -1
                          : _vm.triggerOnlyDuringBusinessHours
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.triggerOnlyDuringBusinessHours,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "triggerOnlyDuringBusinessHours",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.triggerOnlyDuringBusinessHours = $$a.concat(
                                  [$$v]
                                ))
                            } else {
                              $$i > -1 &&
                                (_vm.triggerOnlyDuringBusinessHours = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.triggerOnlyDuringBusinessHours = $$c
                          }
                        }
                      }
                    }),
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t(
                            "CAMPAIGN.ADD.FORM.TRIGGER_ONLY_BUSINESS_HOURS"
                          )
                        ) +
                        "\n      "
                    )
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-footer" },
            [
              _c(
                "woot-button",
                { attrs: { "is-loading": _vm.uiFlags.isCreating } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("CAMPAIGN.ADD.CREATE_BUTTON_TEXT")) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "woot-button",
                {
                  attrs: { variant: "clear" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.onClose($event)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("CAMPAIGN.ADD.CANCEL_BUTTON_TEXT")) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }