var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "read-more" }, [
    _c(
      "div",
      { ref: "content", class: { "shrink-container": _vm.shrink } },
      [
        _vm._t("default"),
        _vm._v(" "),
        _vm.shrink
          ? _c(
              "woot-button",
              {
                staticClass: "read-more-button",
                attrs: {
                  size: "tiny",
                  variant: "smooth",
                  "color-scheme": "primary"
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.$emit("expand")
                  }
                }
              },
              [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("SEARCH.READ_MORE")) + "\n    "
                )
              ]
            )
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }