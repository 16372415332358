var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "search-wrap" }, [
    _c(
      "div",
      { staticClass: "search", class: { "is-active": _vm.showSearchBox } },
      [
        _c("woot-sidemenu-icon"),
        _vm._v(" "),
        _c(
          "router-link",
          { staticClass: "search--link", attrs: { to: _vm.searchUrl } },
          [
            _c(
              "div",
              { staticClass: "icon" },
              [
                _c("fluent-icon", {
                  staticClass: "search--icon",
                  attrs: { icon: "search", size: "16" }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("p", { staticClass: "search--label" }, [
              _vm._v(_vm._s(_vm.$t("CONVERSATION.SEARCH_MESSAGES")))
            ])
          ]
        ),
        _vm._v(" "),
        _c("switch-layout", {
          attrs: { "is-on-expanded-layout": _vm.isOnExpandedLayout },
          on: {
            toggle: function($event) {
              return _vm.$emit("toggle-conversation-layout")
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }