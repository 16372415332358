var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", [
    _c("td", [_vm._v(_vm._s(_vm.macro.name))]),
    _vm._v(" "),
    _c("td", [
      _c(
        "div",
        { staticClass: "avatar-container" },
        [
          _c("thumbnail", {
            attrs: { username: _vm.macro.created_by.name, size: "24px" }
          }),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.macro.created_by.name))])
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("td", [
      _c(
        "div",
        { staticClass: "avatar-container" },
        [
          _c("thumbnail", {
            attrs: { username: _vm.macro.updated_by.name, size: "24px" }
          }),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.macro.updated_by.name))])
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("td", [_vm._v(_vm._s(_vm.visibilityLabel))]),
    _vm._v(" "),
    _c(
      "td",
      { staticClass: "button-wrapper" },
      [
        _c(
          "router-link",
          {
            attrs: {
              to: _vm.addAccountScoping(
                "settings/macros/" + _vm.macro.id + "/edit"
              )
            }
          },
          [
            _c("woot-button", {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip.top",
                  value: _vm.$t("MACROS.EDIT.TOOLTIP"),
                  expression: "$t('MACROS.EDIT.TOOLTIP')",
                  modifiers: { top: true }
                }
              ],
              attrs: {
                variant: "smooth",
                size: "tiny",
                "color-scheme": "secondary",
                "class-names": "grey-btn",
                icon: "edit"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("woot-button", {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip.top",
              value: _vm.$t("MACROS.DELETE.TOOLTIP"),
              expression: "$t('MACROS.DELETE.TOOLTIP')",
              modifiers: { top: true }
            }
          ],
          attrs: {
            variant: "smooth",
            "color-scheme": "alert",
            size: "tiny",
            icon: "dismiss-circle",
            "class-names": "grey-btn"
          },
          on: {
            click: function($event) {
              return _vm.$emit("delete")
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }