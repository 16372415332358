var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box" },
    [
      _c(
        "woot-button",
        {
          attrs: {
            "color-scheme": "success",
            "class-names": "button--fixed-top",
            icon: "arrow-download"
          },
          on: { click: _vm.downloadAgentReports }
        },
        [
          _vm._v(
            "\n    " + _vm._s(_vm.$t("REPORT.DOWNLOAD_AGENT_REPORTS")) + "\n  "
          )
        ]
      ),
      _vm._v(" "),
      _c("report-filter-selector", {
        attrs: {
          "group-by-filter": "",
          "selected-group-by-filter": _vm.selectedGroupByFilter,
          "filter-items-list": _vm.filterItemsList
        },
        on: {
          "date-range-change": _vm.onDateRangeChange,
          "filter-change": _vm.onFilterChange,
          "business-hours-toggle": _vm.onBusinessHoursToggle
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row" },
        _vm._l(_vm.metrics, function(metric, index) {
          return _c("woot-report-stats-card", {
            key: metric.NAME,
            attrs: {
              desc: metric.DESC,
              heading: metric.NAME,
              "info-text": _vm.displayInfoText(metric.KEY),
              index: index,
              "on-click": _vm.changeSelection,
              point: _vm.displayMetric(metric.KEY),
              trend: _vm.calculateTrend(metric.KEY),
              selected: index === _vm.currentSelection
            }
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "report-bar" },
        [
          _vm.accountReport.isFetching
            ? _c("woot-loading-state", {
                attrs: { message: _vm.$t("REPORT.LOADING_CHART") }
              })
            : _c(
                "div",
                { staticClass: "chart-container" },
                [
                  _vm.accountReport.data.length
                    ? _c("woot-bar", {
                        attrs: {
                          collection: _vm.collection,
                          "chart-options": _vm.chartOptions
                        }
                      })
                    : _c("span", { staticClass: "empty-state" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("REPORT.NO_ENOUGH_DATA")) +
                            "\n      "
                        )
                      ])
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }