var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "woot-modal",
    {
      staticClass: "text-left",
      attrs: {
        "modal-type": "right-aligned",
        show: "",
        "on-close": _vm.onClose
      }
    },
    [
      _c("div", { staticClass: "column content" }, [
        _c("p", [
          _c("b", [_vm._v(_vm._s(_vm.$t("TRANSLATE_MODAL.ORIGINAL_CONTENT")))])
        ]),
        _vm._v(" "),
        _c("p", {
          directives: [
            {
              name: "dompurify-html",
              rawName: "v-dompurify-html",
              value: _vm.content,
              expression: "content"
            }
          ]
        }),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _vm.translationsAvailable
          ? _c(
              "div",
              [
                _c("p", [
                  _c("b", [
                    _vm._v(_vm._s(_vm.$t("TRANSLATE_MODAL.TRANSLATED_CONTENT")))
                  ])
                ]),
                _vm._v(" "),
                _vm._l(_vm.translations, function(translation, language) {
                  return _c("div", { key: language }, [
                    _c("p", [_c("strong", [_vm._v(_vm._s(language) + ":")])]),
                    _vm._v(" "),
                    _c("p", {
                      directives: [
                        {
                          name: "dompurify-html",
                          rawName: "v-dompurify-html",
                          value: translation,
                          expression: "translation"
                        }
                      ]
                    }),
                    _vm._v(" "),
                    _c("br")
                  ])
                })
              ],
              2
            )
          : _c("p", [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("TRANSLATE_MODAL.NO_TRANSLATIONS_AVAILABLE")) +
                  "\n    "
              )
            ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }