var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column content-box" },
    [
      _c(
        "woot-button",
        {
          attrs: {
            "color-scheme": "success",
            "class-names": "button--fixed-top",
            icon: "add-circle"
          },
          on: {
            click: function($event) {
              return _vm.openAddPopup()
            }
          }
        },
        [
          _vm._v(
            "\n    " + _vm._s(_vm.$t("AUTOMATION.HEADER_BTN_TXT")) + "\n  "
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "small-8 columns with-right-space" },
          [
            !_vm.uiFlags.isFetching && !_vm.records.length
              ? _c("p", { staticClass: "no-items-error-message" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("AUTOMATION.LIST.404")) +
                      "\n      "
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.uiFlags.isFetching
              ? _c("woot-loading-state", {
                  attrs: { message: _vm.$t("AUTOMATION.LOADING") }
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.uiFlags.isFetching && _vm.records.length
              ? _c("table", { staticClass: "woot-table" }, [
                  _c(
                    "thead",
                    _vm._l(_vm.$t("AUTOMATION.LIST.TABLE_HEADER"), function(
                      thHeader
                    ) {
                      return _c("th", { key: thHeader }, [
                        _vm._v(
                          "\n            " + _vm._s(thHeader) + "\n          "
                        )
                      ])
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.records, function(automation, index) {
                      return _c("tr", { key: index }, [
                        _c("td", [_vm._v(_vm._s(automation.name))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(automation.description))]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("woot-switch", {
                              attrs: { value: automation.active },
                              on: {
                                input: function($event) {
                                  return _vm.toggleAutomation(
                                    automation,
                                    automation.active
                                  )
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.readableTime(automation.created_on))
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "button-wrapper" },
                          [
                            _c("woot-button", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip.top",
                                  value: _vm.$t("AUTOMATION.FORM.EDIT"),
                                  expression: "$t('AUTOMATION.FORM.EDIT')",
                                  modifiers: { top: true }
                                }
                              ],
                              attrs: {
                                variant: "smooth",
                                size: "tiny",
                                "color-scheme": "secondary",
                                "class-names": "grey-btn",
                                "is-loading": _vm.loading[automation.id],
                                icon: "edit"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.openEditPopup(automation)
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("woot-button", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip.top",
                                  value: _vm.$t("AUTOMATION.CLONE.TOOLTIP"),
                                  expression: "$t('AUTOMATION.CLONE.TOOLTIP')",
                                  modifiers: { top: true }
                                }
                              ],
                              attrs: {
                                variant: "smooth",
                                size: "tiny",
                                "color-scheme": "primary",
                                "class-names": "grey-btn",
                                "is-loading": _vm.loading[automation.id],
                                icon: "copy"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.cloneAutomation(automation.id)
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("woot-button", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip.top",
                                  value: _vm.$t("AUTOMATION.FORM.DELETE"),
                                  expression: "$t('AUTOMATION.FORM.DELETE')",
                                  modifiers: { top: true }
                                }
                              ],
                              attrs: {
                                variant: "smooth",
                                "color-scheme": "alert",
                                size: "tiny",
                                icon: "dismiss-circle",
                                "class-names": "grey-btn",
                                "is-loading": _vm.loading[automation.id]
                              },
                              on: {
                                click: function($event) {
                                  return _vm.openDeletePopup(automation, index)
                                }
                              }
                            })
                          ],
                          1
                        )
                      ])
                    }),
                    0
                  )
                ])
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "small-4 columns" }, [
          _c("span", {
            directives: [
              {
                name: "dompurify-html",
                rawName: "v-dompurify-html",
                value: _vm.$t("AUTOMATION.SIDEBAR_TXT"),
                expression: "$t('AUTOMATION.SIDEBAR_TXT')"
              }
            ]
          })
        ])
      ]),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: {
            show: _vm.showAddPopup,
            size: "medium",
            "on-close": _vm.hideAddPopup
          },
          on: {
            "update:show": function($event) {
              _vm.showAddPopup = $event
            }
          }
        },
        [
          _vm.showAddPopup
            ? _c("add-automation-rule", {
                attrs: { "on-close": _vm.hideAddPopup },
                on: { saveAutomation: _vm.submitAutomation }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("woot-delete-modal", {
        attrs: {
          show: _vm.showDeleteConfirmationPopup,
          "on-close": _vm.closeDeletePopup,
          "on-confirm": _vm.confirmDeletion,
          title: _vm.$t("LABEL_MGMT.DELETE.CONFIRM.TITLE"),
          message: _vm.$t("AUTOMATION.DELETE.CONFIRM.MESSAGE"),
          "message-value": _vm.deleteMessage,
          "confirm-text": _vm.deleteConfirmText,
          "reject-text": _vm.deleteRejectText
        },
        on: {
          "update:show": function($event) {
            _vm.showDeleteConfirmationPopup = $event
          }
        }
      }),
      _vm._v(" "),
      _c(
        "woot-modal",
        {
          attrs: {
            show: _vm.showEditPopup,
            size: "medium",
            "on-close": _vm.hideEditPopup
          },
          on: {
            "update:show": function($event) {
              _vm.showEditPopup = $event
            }
          }
        },
        [
          _vm.showEditPopup
            ? _c("edit-automation-rule", {
                attrs: {
                  "on-close": _vm.hideEditPopup,
                  "selected-response": _vm.selectedResponse
                },
                on: { saveAutomation: _vm.submitAutomation }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("woot-confirm-modal", {
        ref: "confirmDialog",
        attrs: {
          title: _vm.toggleModalTitle,
          description: _vm.toggleModalDescription
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }