var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "columns profile--settings" },
    [
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.updateUser("profile")
            }
          }
        },
        [
          _c("div", { staticClass: "small-12 row profile--settings--row" }, [
            _c("div", { staticClass: "columns small-3" }, [
              _c("h4", { staticClass: "block-title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t("PROFILE_SETTINGS.FORM.PROFILE_SECTION.TITLE")
                    ) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  _vm._s(_vm.$t("PROFILE_SETTINGS.FORM.PROFILE_SECTION.NOTE"))
                )
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "columns small-9 medium-5" },
              [
                _c("woot-avatar-uploader", {
                  attrs: {
                    label: _vm.$t("PROFILE_SETTINGS.FORM.PROFILE_IMAGE.LABEL"),
                    src: _vm.avatarUrl
                  },
                  on: { change: _vm.handleImageUpload }
                }),
                _vm._v(" "),
                _vm.showDeleteButton
                  ? _c(
                      "div",
                      { staticClass: "avatar-delete-btn" },
                      [
                        _c(
                          "woot-button",
                          {
                            attrs: {
                              type: "button",
                              "color-scheme": "alert",
                              variant: "hollow",
                              size: "small"
                            },
                            on: { click: _vm.deleteAvatar }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t("PROFILE_SETTINGS.DELETE_AVATAR")
                                ) +
                                "\n          "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("label", { class: { error: _vm.$v.name.$error } }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("PROFILE_SETTINGS.FORM.NAME.LABEL")) +
                      "\n          "
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.name,
                        expression: "name"
                      }
                    ],
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t(
                        "PROFILE_SETTINGS.FORM.NAME.PLACEHOLDER"
                      )
                    },
                    domProps: { value: _vm.name },
                    on: {
                      input: [
                        function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.name = $event.target.value
                        },
                        _vm.$v.name.$touch
                      ]
                    }
                  }),
                  _vm._v(" "),
                  _vm.$v.name.$error
                    ? _c("span", { staticClass: "message" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("PROFILE_SETTINGS.FORM.NAME.ERROR")) +
                            "\n          "
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("label", { class: { error: _vm.$v.displayName.$error } }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t("PROFILE_SETTINGS.FORM.DISPLAY_NAME.LABEL")
                      ) +
                      "\n          "
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.displayName,
                        expression: "displayName"
                      }
                    ],
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t(
                        "PROFILE_SETTINGS.FORM.DISPLAY_NAME.PLACEHOLDER"
                      )
                    },
                    domProps: { value: _vm.displayName },
                    on: {
                      input: [
                        function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.displayName = $event.target.value
                        },
                        _vm.$v.displayName.$touch
                      ]
                    }
                  })
                ]),
                _vm._v(" "),
                !_vm.globalConfig.disableUserProfileUpdate
                  ? _c("label", { class: { error: _vm.$v.email.$error } }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("PROFILE_SETTINGS.FORM.EMAIL.LABEL")) +
                          "\n          "
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.email,
                            expression: "email",
                            modifiers: { trim: true }
                          }
                        ],
                        attrs: {
                          type: "email",
                          placeholder: _vm.$t(
                            "PROFILE_SETTINGS.FORM.EMAIL.PLACEHOLDER"
                          )
                        },
                        domProps: { value: _vm.email },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.email = $event.target.value.trim()
                            },
                            _vm.$v.email.$touch
                          ],
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.$v.email.$error
                        ? _c("span", { staticClass: "message" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t("PROFILE_SETTINGS.FORM.EMAIL.ERROR")
                                ) +
                                "\n          "
                            )
                          ])
                        : _vm._e()
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "woot-button",
                  {
                    attrs: {
                      type: "submit",
                      "is-loading": _vm.isProfileUpdating
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("PROFILE_SETTINGS.BTN_TEXT")) +
                        "\n        "
                    )
                  ]
                )
              ],
              1
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("message-signature"),
      _vm._v(" "),
      _c("div", { staticClass: "profile--settings--row row" }, [
        _c("div", { staticClass: "columns small-3" }, [
          _c("h4", { staticClass: "block-title" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("PROFILE_SETTINGS.FORM.SEND_MESSAGE.TITLE")) +
                "\n      "
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("PROFILE_SETTINGS.FORM.SEND_MESSAGE.NOTE")) +
                "\n      "
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "columns small-9 medium-5 card-preview" },
          _vm._l(_vm.keyOptions, function(keyOption) {
            return _c(
              "button",
              {
                key: keyOption.key,
                staticClass: "preview-button",
                on: {
                  click: function($event) {
                    return _vm.toggleEditorMessageKey(keyOption.key)
                  }
                }
              },
              [
                _c("preview-card", {
                  attrs: {
                    heading: keyOption.heading,
                    content: keyOption.content,
                    src: keyOption.src,
                    active: _vm.isEditorHotKeyEnabled(
                      _vm.uiSettings,
                      keyOption.key
                    )
                  }
                })
              ],
              1
            )
          }),
          0
        )
      ]),
      _vm._v(" "),
      !_vm.globalConfig.disableUserProfileUpdate
        ? _c("change-password")
        : _vm._e(),
      _vm._v(" "),
      _c("notification-settings"),
      _vm._v(" "),
      _c("div", { staticClass: "profile--settings--row row" }, [
        _c("div", { staticClass: "columns small-3" }, [
          _c("h4", { staticClass: "block-title" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("PROFILE_SETTINGS.FORM.ACCESS_TOKEN.TITLE")) +
                "\n      "
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.useInstallationName(
                    _vm.$t("PROFILE_SETTINGS.FORM.ACCESS_TOKEN.NOTE"),
                    _vm.globalConfig.installationName
                  )
                ) +
                "\n      "
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "columns small-9 medium-5" },
          [
            _c("masked-text", {
              attrs: { value: _vm.currentUser.access_token }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }